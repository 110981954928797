import React, { useState, useEffect } from 'react'
import { ManageOrdersforInvoice, orderItemDetails } from '../../api/apiHandler';
import { useNavigate, Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import $ from "jquery";
import { Helmet } from 'react-helmet';
import "jquery/dist/jquery.min.js";


function Invoice() {
    const Print = () => {
        //console.log('print');  
        // $('.buthide').remove();
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    const [order, setOrder] = useState([]);
    const navigate = useNavigate()
    const [item, setItem] = useState([])

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getonload();
    }, []);
    let ists = window.location.pathname.split('/')[3]
    const getonload = () => {
        ManageOrdersforInvoice({ id: ists }).then((response) => {
            if (response.data.code == 1) {
                setOrder(response.data.data[0])
                Swal.close();
            } else {
                Swal.close()
            }
        });
        orderItemDetails({ id: ists }).then((response) => {
            if (response.data.code == 1) {
                setItem(response.data.data)
            } else {
            }
        });
    }

    // const orsdedeat = (id) => {
    //     setItem([])
    //     orderItemDetails({ id: id }).then((response) => {
    //         if (response.data.code == 1) {
    //             setItem(response.data.data)
    //         } else {
    //         }
    //     });
    // }
    return (
        <>
            <section className="content">
                <div className="block-header">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <h2>
                                Invoice
                            </h2>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                        <ul className="breadcrumb float-md-right">
                                <li className="breadcrumb-item"><Link to="/dashboard"><i className="zmdi zmdi-home"></i> Ballina's</Link></li>
                                <li className="breadcrumb-item">Manage Order</li>
                                <li className="breadcrumb-item active">Invoice</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid" >
                    <div className="row clearfix" >
                <div className='float-right buthide'>
                            <button className="btn btn-raised btn-default waves-effect " style={{ margin: '15px' }} onClick={() => (navigate('/order'))}>Go Back</button>
                        </div>
                        <div className="col-lg-12" id='printablediv'>
                            <div className="card">
                                <div className="header">
                                    {/* <h2>Invoices Detail</h2> */}
                                </div>
                                <div className="body" >
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <img
                                                src="http://3.26.203.80/api/logo192.png"
                                                width={80}
                                                alt="ballina"
                                            />
                                            <h4 className="float-md-right">
                                                Order Id # <br />
                                                <strong>{order.order_id}</strong>
                                            </h4>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <address>
                                                <strong>{order.name}</strong>
                                                <br />
                                                {order.address}
                                                <br />
                                                &#9742; {order.phone}
                                            </address>
                                        </div>
                                        <div className="col-md-6 col-sm-6 text-right">
                                            <p>
                                                <strong>Order Date: </strong> {order.inserted_at}
                                            </p>
                                            <p>
                                                <strong>Delivery DateTime: </strong> {order.deliver_datetime}
                                            </p>
                                            <p className="m-t-10">
                                                <strong>Order Status: </strong>{order.status}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-40" />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                <table
                                                    id="mainTable"
                                                    className="table table-striped"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Product Image</th>
                                                            <th>Product Name</th>
                                                            <th>Quantity</th>
                                                            <th>Unit Cost</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item && item.map((item, int) => {
                                                            return (
                                                                <tr key={int}>
                                                                    <td>*</td>
                                                                    <td><img className="rounded image-popup" alt="product" style={{ width: 70, height: 70 }} src={item.image} /></td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.quantity}</td>
                                                                    <td>$ {item.price}</td>
                                                                    <td>$ {item.price * item.quantity}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12 text-right">
                                            <p className="text-right">
                                                <b>Sub-total:</b> {order.payment_surcharge} $
                                            </p>
                                            <p className="text-right">Shipping: $ {order.shipping}</p>
                                            <p className="text-right">TAX: $ {order.tax}</p>
                                            <hr />
                                            <h3 className="text-right">ASD $ {order.total_payout} </h3>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="hidden-print col-md-12 text-right">
                                        <a href="#" className="btn btn-raised btn-success">
                                            <i className="zmdi zmdi-print" onClick={Print} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Invoice
