import React, { useState, useEffect } from 'react'
import { dashBoard } from '../../api/apiHandler';
import { useNavigate, Link } from "react-router-dom";
import Swal from 'sweetalert2';


function Dashboard() {
    const [dash, setDash] = useState([]);
    let navigate = useNavigate();
    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
            Swal.showLoading()
            }
        })
        getonload();
    }, []);
    const getonload = () => {
        dashBoard({}).then((response) => {
            if (response.data.code == 1) {
                setDash(response.data.data[0])
                Swal.close();
            } else {
                Swal.close()
            }
        });
    }
    return (
        <>
            <section className="content blog-page">
                <div className="block-header">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <h2>Ballina's Dashboard
                                <small>Welcome to Ballina's Admin Panel</small>
                            </h2>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <ul className="breadcrumb float-md-right">
                                <li className="breadcrumb-item"><Link to="/dashboard"><i className="zmdi zmdi-home"></i> Ballina</Link></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br />
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="card l-slategray">
                                <div className="body">
                                    <h3 className="m-t-0 c-0 text-white" >{dash?.vendor == '' ? 0 : dash.vendor}</h3>
                                    <h5 className="m-b-0 c-0 text-white" >Total Vendor</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="card l-slategray">
                                <div className="body">
                                    <h3 className="m-t-0 c-0 text-white" >{dash?.orders == '' ? 0 : dash.orders}</h3>
                                    <h5 className="m-b-0 c-0 text-white" >Total Orders</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-6">
                            <div className="card l-slategray">
                                <div className="body">
                                    <h3 className="m-t-0 c-0 text-white" >$ {dash?.earning == null ? 0 : dash.earning}</h3>
                                    <h5 className="m-b-0 c-0 text-white" >Total Earning</h5>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default Dashboard
