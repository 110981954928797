import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import React, { useState, useEffect } from 'react'
import { ManageOrders, orderItemDetails, manageOrderOntheway, manageOrderDelvivers } from '../../api/apiHandler';
import { useNavigate, Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import $ from "jquery";
import { Helmet } from 'react-helmet';
import "jquery/dist/jquery.min.js";

function ManageOrder() {
    const [order, setOrder] = useState([]);
    const navigate = useNavigate()
    const [item, setItem] = useState([])

    const [tag, setTag] = useState('received');

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getonload();
    }, []);
    const getonload = () => {
        // setOrder([])
        Swal.fire({
              title: 'Please wait...',
              didOpen: () => {
              Swal.showLoading()
              }
          })
        ManageOrders({ tag: tag }).then((response) => {
            if (response.data.code == 1) {
                setOrder(response.data.data)
                Swal.close();
            } else {
                Swal.close()
            }
        });
    }

    const changeOrderdE = (taqg) => {
        Swal.fire({
              title: 'Please wait...',
              didOpen: () => {
              Swal.showLoading()
              }
          })
        ManageOrders({ tag: taqg }).then((response) => {
            if (response.data.code == 1) {
                setOrder(response.data.data)
                Swal.close();
            } else {
                Swal.close()
            }
        });
    }

    const orsdedeat = (id) => {
        setItem([])
        orderItemDetails({ id: id }).then((response) => {
            if (response.data.code == 1) {
                setItem(response.data.data)
            } else {
            }
        });
    }

    const OrderOnway = (id) => {
        manageOrderOntheway({ id: id }).then((response) => {
            if (response.data.code == 1) {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                  })                  
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  })
                setOrder([]);
                getonload()
            } else {
            }
        });
    }

    const OrderDeliverPaymne = (id) => {
        Swal.fire({
            title: 'Is Payment Collected?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Payment Collected!'
          }).then((result) => {
            if (result.isConfirmed) {
                manageOrderDelvivers({ id : id }).then((response) => {
                  if (response.data.code == 1) {
                  Swal.fire({
                    icon: 'success',
                    title: response.data.message
                  })
                  setOrder([]);
                    getonload()
              }  else  {
                getonload()
                }
              }) 
            } 
          })
    }

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            cellExport: row => row.id,
            width: '75px',
            sortable: true,
        },
        {
            name: 'Order ID',
            selector: row => row.order_id,
            cellExport: row => row.order_id,
            sortable: true,
        },
        {
            name: 'Vendor Name',
            selector: row => row.name,
            cellExport: row => row.name,
            sortable: true,
        },
        {
            name: 'Vendor Address',
            selector: row => row.address,
            cellExport: row => row.address,
            sortable: true,
        },
        {
            name: 'Total Payment',
            selector: row => row.total_payout,
            cellExport: row => row.total_payout,
        },
        {
            name: 'Delivery Date',
            selector: row => row.deliver_datetime,
            cell: (row) => <p>{row.deliver_datetime}</p>,
            sortable: true,
        },
        {
            name: 'Order Status',
            selector: row => row.status,
            cell: (row) => <p>{row.status}</p>
        },
        {
            name: 'Action',
            selector: row => row.order_id,
            cell: (row) => <><button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.order_id} onClick={() => { orsdedeat(row.order_id) }} data-toggle="modal" data-target="#editmodel"><i className="material-icons" id={row.order_id} onClick={() => { orsdedeat(row.order_id) }}>visibility</i>  </button><button type="button" className="btn" style={{ boxShadow: 'none' }} id={row.order_id} onClick={() => { navigate(`/order/invoice/${row.order_id}`) }} ><i class="zmdi zmdi-print" onClick={() => { navigate(`/order/invoice/${row.order_id}`) }}></i> </button></>
        }, 
        {
            name: <>{(tag == 'completed' || tag == 'cancelled') ? '' :'Change Order Status'}</>,
            selector: row => row.order_id,
            cell: (row) => <>{(tag == 'completed' || tag == 'cancelled') ? '' : row.status == 'Order Accepted' ? <button type="button" className="btn btn-primary waves-effect rounded waves-float" id={row.id} onClick={() => { OrderOnway(row.order_id) }} > <strong>On The Way</strong> </button> : <button type="button" className="btn btn-success waves-effect rounded waves-float" id={row.id} onClick={() => { OrderDeliverPaymne(row.order_id) }} > <strong>Delivered</strong> </button>}</>
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
    };

    return (
        <>
            <Helmet>
                <script>{`
                  $(document).ready(function() {
                      $('body').magnificPopup({
                      delegate: '.image-popup',
                      type: 'image',
                      image:{
                              verticalFit:true,
                      },
                      zoom:{
                              enabled:true,
                              duration:300
                      }
                  });
                  });`}</script>
            </Helmet>
            <section className="content">
                <div className="block-header">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12">
                            <h2>Manage Order
                                <small className="text-muted"></small>
                            </h2>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <ul className="breadcrumb float-md-right">
                                <li className="breadcrumb-item"><Link to="/dashboard"><i className="zmdi zmdi-home"></i> Ballina's</Link></li>
                                <li className="breadcrumb-item active">Manage Order</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card">
                                <div className=''>
                                    <button className="btn btn-raised btn-primary waves-effect" style={{ margin: '15px' }} onClick={() => { setTag(''); setTag('received'); setOrder([]); changeOrderdE('received') }}>Received Order</button>
                                    <button className="btn btn-raised btn-primary waves-effect" style={{ margin: '15px' }} onClick={() => { setTag(''); setTag('completed'); setOrder([]); changeOrderdE('completed') }}>Completed Order</button>
                                    <button className="btn btn-raised btn-primary waves-effect" style={{ margin: '15px' }} onClick={() => { setTag(''); setTag('cancelled'); setOrder([]); changeOrderdE('cancelled') }}>Canceled Order</button>
                                </div>
                                <div className="body">
                                    <div className='table-responsive'>
                                        <DataTableExtensions columns={columns} data={order}>
                                            <DataTable columns={columns} data={order} responsive pagination customStyles={customStyles} />
                                        </DataTableExtensions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="modal fade" id="editmodel" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="largeModalLabel">Order Details</h4>
                        </div>
                        <div class="modal-body">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Product Image</th>
                                        <th>Product Name</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Item</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {item && item.map((item, int) => {
                                        return (
                                            <tr key={int}>
                                                <td><img className="rounded image-popup" alt="product" style={{ width: 70, height: 70 }} src={item.image} /></td>
                                                <td>{item.name}</td>
                                                <td>{item.price}</td>
                                                <td>{item.qty}</td>
                                                <td>{item.quantity}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-link waves-effect" data-dismiss="modal">CLOSE</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="invoicemodel" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title" id="largeModalLabel">Invoice Details</h4>
                        </div>
                        <div class="modal-body">
                            {/* <div className='helkj'>
                                    <div class="col-md-12">
                                        <img src="https://ballians3.s3.amazonaws.com/profileimage/1696694158477wcH6oEwXmwiG1vQ29iwffz.jpeg" width="70" alt="Ballina" />
                                        <h4 class="float-md-right">ORDER ID # <br />
                                            <strong>2015-04-5654667546</strong>
                                        </h4>
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <strong>Twitter, Inc.</strong><br />
                                        795 Folsom Ave, Suite 546<br />
                                        San Francisco, CA 54656<br />
                                        <abbr title="Phone">P:</abbr> (123) 456-34636
                                    </div>
                                    <div class="col-md-6 col-sm-6">
                                        <p><strong>Order Date: </strong> Jun 15, 2016</p>
                                        <p class="m-t-10"><strong>Order Status: </strong> <span class="badge bg-orange">Pending</span></p>
                                        <p class="m-t-10"><strong>Order ID: </strong> #123456</p>
                                    </div>
                                    <div class="col-md-12 text-right">
                                        <p class="text-right"><b>Sub-total:</b> 2930.00</p>
                                        <p class="text-right">Discout: 12.9%</p>
                                        <p class="text-right">VAT: 12.9%</p>
                                        <hr />
                                        <h3 class="text-right">ASD 2930.00</h3>
                                    </div>
                                    </div> */}
                                    {/* <div class="hidden-print col-md-12 text-right">
                                    <a href="#" class="btn btn-raised btn-default"><i class="zmdi zmdi-print"></i></a>
                                    <a href="#" class="btn btn-raised btn-default">Submit</a>
                                </div> */}
                            {/* <div class="">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <img src="assets/images/logo-placeholder.jpg" width="70" alt="velonic" />
                                        <h4 class="float-md-right">Invoice # <br />
                                            <strong>2015-04-5654667546</strong>
                                        </h4>
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-md-6 col-sm-6">
                                        <strong>Twitter, Inc.</strong><br />
                                        795 Folsom Ave, Suite 546<br />
                                        San Francisco, CA 54656<br />
                                        <abbr title="Phone">P:</abbr> (123) 456-34636
                                    </div>
                                    <div class="col-md-6 col-sm-6 text-right">
                                        <p><strong>Order Date: </strong> Jun 15, 2016</p>
                                        <p class="m-t-10"><strong>Order Status: </strong> <span class="badge bg-orange">Pending</span></p>
                                        <p class="m-t-10"><strong>Order ID: </strong> #123456</p>
                                    </div>
                                </div>
                                <div class="mt-40"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="table-responsive">
                                            <table id="mainTable" class="table table-striped" style="cursor: pointer;">
                                                <thead>
                                                    <tr><th>#</th>
                                                        <th>Item</th>
                                                        <th>Description</th>
                                                        <th>Quantity</th>
                                                        <th>Unit Cost</th>
                                                        <th>Total</th>
                                                    </tr></thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td>LCD</td>
                                                        <td>Lorem ipsum dolor sit amet.</td>
                                                        <td>1</td>
                                                        <td>$380</td>
                                                        <td>$380</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td>Mobile</td>
                                                        <td>There are many variations of passages of Lorem Ipsum</td>
                                                        <td>5</td>
                                                        <td>$50</td>
                                                        <td>$250</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                        <td>LED</td>
                                                        <td>Lorem ipsum dolor sit amet.</td>
                                                        <td>2</td>
                                                        <td>$500</td>
                                                        <td>$1000</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                        <td>LCD</td>
                                                        <td>Contrary to popular belief, Lorem Ipsum is not simply random text</td>
                                                        <td>3</td>
                                                        <td>$300</td>
                                                        <td>$900</td>
                                                    </tr>
                                                    <tr>
                                                        <td>5</td>
                                                        <td>Mobile</td>
                                                        <td>The standard chunk of Lorem Ipsum used since the 1500s</td>
                                                        <td>5</td>
                                                        <td>$80</td>
                                                        <td>$400</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-md-12 text-right">
                                        <p class="text-right"><b>Sub-total:</b> 2930.00</p>
                                        <p class="text-right">Discout: 12.9%</p>
                                        <p class="text-right">VAT: 12.9%</p>
                                        <hr />
                                        <h3 class="text-right">USD 2930.00</h3>
                                    </div>
                                </div>
                                <hr />
                                <div class="hidden-print col-md-12 text-right">
                                    <a href="#" class="btn btn-raised btn-success"><i class="zmdi zmdi-print"></i></a>
                                    <a href="#" class="btn btn-raised btn-default">Submit</a>
                                </div>
                            </div> */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-link waves-effect" data-dismiss="modal">Print</button>
                            <button type="button" className="btn btn-link waves-effect" data-dismiss="modal">CLOSE</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageOrder
